import { UsersModuleMessages, TranslationUsersModuleMessages } from "../interfaces";

export const messages: TranslationUsersModuleMessages = {
  [UsersModuleMessages.createUserSuccess]: "User successfully registered",
  [UsersModuleMessages.confirmationEmailSent]: "Confirmation email sent",
  [UsersModuleMessages.createUserError]: "Failed to register new user",
  [UsersModuleMessages.readUserSuccess]: "User(s) successfully returned",
  [UsersModuleMessages.noUserFound]: "No user found",
  [UsersModuleMessages.readUserError]: "Failed to fetch user(s)",
  [UsersModuleMessages.updateUserSuccess]: "User successfully edited",
  [UsersModuleMessages.userNotFound]: "User not registered in our system",
  [UsersModuleMessages.updateUserError]: "Failed to edit user",
  [UsersModuleMessages.deleteUserSuccess]: "User successfully deleted",
  [UsersModuleMessages.deleteUserError]: "Failed to delete user",
  [UsersModuleMessages.inactiveAccountCheckEmail]: "Account not yet activated, check your email for activation",
  [UsersModuleMessages.inactiveUser]: "Inactive user",
  [UsersModuleMessages.userNotAdmin]: "User does not have administrator rights",
  [UsersModuleMessages.readUserRestrictedVehiclesSuccess]: "User's restricted vehicles successfully returned",
  [UsersModuleMessages.readUserRestrictedVehiclesError]: "Failed to fetch user's restricted vehicles"
};
